/* Control.svelte generated by Svelte v3.31.0 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	listen_dev,
	noop,
	run_all,
	safe_not_equal,
	set_data_dev,
	set_input_value,
	set_style,
	space,
	text,
	to_number,
	validate_slots
} from "svelte/internal";

const file = "Control.svelte";

function create_fragment(ctx) {
	let div;
	let label;
	let t0;
	let t1;
	let input0;
	let t2;
	let input1;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element("div");
			label = element("label");
			t0 = text(/*name*/ ctx[1]);
			t1 = space();
			input0 = element("input");
			t2 = space();
			input1 = element("input");
			attr_dev(label, "for", "control");
			attr_dev(label, "class", "svelte-6r20jy");
			add_location(label, file, 59, 1, 859);
			attr_dev(input0, "type", "number");
			attr_dev(input0, "min", /*min*/ ctx[2]);
			attr_dev(input0, "max", /*max*/ ctx[3]);
			attr_dev(input0, "step", /*step*/ ctx[4]);
			attr_dev(input0, "id", "control");
			attr_dev(input0, "class", "svelte-6r20jy");
			add_location(input0, file, 60, 1, 896);
			attr_dev(input1, "type", "range");
			attr_dev(input1, "min", /*min*/ ctx[2]);
			attr_dev(input1, "max", /*max*/ ctx[3]);
			attr_dev(input1, "step", /*step*/ ctx[4]);
			set_style(input1, "width", /*width*/ ctx[6] + "px");
			set_style(input1, "--val", /*percent*/ ctx[5] + "px");
			attr_dev(input1, "class", "svelte-6r20jy");
			add_location(input1, file, 61, 1, 981);
			attr_dev(div, "class", "row svelte-6r20jy");
			add_location(div, file, 58, 0, 840);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, label);
			append_dev(label, t0);
			append_dev(div, t1);
			append_dev(div, input0);
			set_input_value(input0, /*value*/ ctx[0]);
			append_dev(div, t2);
			append_dev(div, input1);
			set_input_value(input1, /*value*/ ctx[0]);

			if (!mounted) {
				dispose = [
					listen_dev(input0, "input", /*input0_input_handler*/ ctx[7]),
					listen_dev(input1, "change", /*input1_change_input_handler*/ ctx[8]),
					listen_dev(input1, "input", /*input1_change_input_handler*/ ctx[8])
				];

				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*name*/ 2) set_data_dev(t0, /*name*/ ctx[1]);

			if (dirty & /*min*/ 4) {
				attr_dev(input0, "min", /*min*/ ctx[2]);
			}

			if (dirty & /*max*/ 8) {
				attr_dev(input0, "max", /*max*/ ctx[3]);
			}

			if (dirty & /*step*/ 16) {
				attr_dev(input0, "step", /*step*/ ctx[4]);
			}

			if (dirty & /*value*/ 1 && to_number(input0.value) !== /*value*/ ctx[0]) {
				set_input_value(input0, /*value*/ ctx[0]);
			}

			if (dirty & /*min*/ 4) {
				attr_dev(input1, "min", /*min*/ ctx[2]);
			}

			if (dirty & /*max*/ 8) {
				attr_dev(input1, "max", /*max*/ ctx[3]);
			}

			if (dirty & /*step*/ 16) {
				attr_dev(input1, "step", /*step*/ ctx[4]);
			}

			if (dirty & /*percent*/ 32) {
				set_style(input1, "--val", /*percent*/ ctx[5] + "px");
			}

			if (dirty & /*value*/ 1) {
				set_input_value(input1, /*value*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots("Control", slots, []);
	let { name = "Control" } = $$props;
	let { min = 0 } = $$props;
	let { value = min } = $$props;
	let { max = 1 } = $$props;
	let { step = (max - min) / 100 } = $$props;
	let width = 130;
	let percent;
	const writable_props = ["name", "min", "value", "max", "step"];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== "$$") console.warn(`<Control> was created with unknown prop '${key}'`);
	});

	function input0_input_handler() {
		value = to_number(this.value);
		$$invalidate(0, value);
	}

	function input1_change_input_handler() {
		value = to_number(this.value);
		$$invalidate(0, value);
	}

	$$self.$$set = $$props => {
		if ("name" in $$props) $$invalidate(1, name = $$props.name);
		if ("min" in $$props) $$invalidate(2, min = $$props.min);
		if ("value" in $$props) $$invalidate(0, value = $$props.value);
		if ("max" in $$props) $$invalidate(3, max = $$props.max);
		if ("step" in $$props) $$invalidate(4, step = $$props.step);
	};

	$$self.$capture_state = () => ({
		name,
		min,
		value,
		max,
		step,
		width,
		percent
	});

	$$self.$inject_state = $$props => {
		if ("name" in $$props) $$invalidate(1, name = $$props.name);
		if ("min" in $$props) $$invalidate(2, min = $$props.min);
		if ("value" in $$props) $$invalidate(0, value = $$props.value);
		if ("max" in $$props) $$invalidate(3, max = $$props.max);
		if ("step" in $$props) $$invalidate(4, step = $$props.step);
		if ("width" in $$props) $$invalidate(6, width = $$props.width);
		if ("percent" in $$props) $$invalidate(5, percent = $$props.percent);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*value, min, max*/ 13) {
			$: $$invalidate(5, percent = width * (value - min) / (max - min));
		}
	};

	return [
		value,
		name,
		min,
		max,
		step,
		percent,
		width,
		input0_input_handler,
		input1_change_input_handler
	];
}

class Control extends SvelteComponentDev {
	constructor(options) {
		super(options);

		init(this, options, instance, create_fragment, safe_not_equal, {
			name: 1,
			min: 2,
			value: 0,
			max: 3,
			step: 4
		});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Control",
			options,
			id: create_fragment.name
		});
	}

	get name() {
		throw new Error("<Control>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set name(value) {
		throw new Error("<Control>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get min() {
		throw new Error("<Control>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set min(value) {
		throw new Error("<Control>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get value() {
		throw new Error("<Control>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set value(value) {
		throw new Error("<Control>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get max() {
		throw new Error("<Control>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set max(value) {
		throw new Error("<Control>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get step() {
		throw new Error("<Control>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set step(value) {
		throw new Error("<Control>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}
}

export default Control;