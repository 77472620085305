/* App.svelte generated by Svelte v3.31.0 */
import {
	SvelteComponentDev,
	add_flush_callback,
	add_location,
	append_dev,
	attr_dev,
	bind,
	binding_callbacks,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	group_outros,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	safe_not_equal,
	set_data_dev,
	space,
	text,
	transition_in,
	transition_out,
	validate_each_argument,
	validate_slots
} from "svelte/internal";

import Control from "./Control.svelte";
import Shader from "./Shader.svelte";
import yomqo from "./yomqo.frag";
import bubble from "./bubble.frag";
import openeye from "./openeye.frag";
import test from "./test.frag";
const file = "App.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	child_ctx[6] = list;
	child_ctx[7] = i;
	return child_ctx;
}

// (142:1) {#each controlsArrays[shaderName] as c}
function create_each_block(ctx) {
	let control;
	let updating_value;
	let current;

	function control_value_binding(value) {
		/*control_value_binding*/ ctx[4].call(null, value, /*c*/ ctx[5]);
	}

	let control_props = {
		name: /*c*/ ctx[5].name,
		min: /*c*/ ctx[5].min,
		max: /*c*/ ctx[5].max,
		step: /*c*/ ctx[5].step
	};

	if (/*c*/ ctx[5].value !== void 0) {
		control_props.value = /*c*/ ctx[5].value;
	}

	control = new Control({ props: control_props, $$inline: true });
	binding_callbacks.push(() => bind(control, "value", control_value_binding));

	const block = {
		c: function create() {
			create_component(control.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(control, target, anchor);
			current = true;
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			const control_changes = {};
			if (dirty & /*controlsArrays, shaderName*/ 3) control_changes.name = /*c*/ ctx[5].name;
			if (dirty & /*controlsArrays, shaderName*/ 3) control_changes.min = /*c*/ ctx[5].min;
			if (dirty & /*controlsArrays, shaderName*/ 3) control_changes.max = /*c*/ ctx[5].max;
			if (dirty & /*controlsArrays, shaderName*/ 3) control_changes.step = /*c*/ ctx[5].step;

			if (!updating_value && dirty & /*controlsArrays, shaderName*/ 3) {
				updating_value = true;
				control_changes.value = /*c*/ ctx[5].value;
				add_flush_callback(() => updating_value = false);
			}

			control.$set(control_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(control.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(control.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(control, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(142:1) {#each controlsArrays[shaderName] as c}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let shader;
	let t0;
	let div3;
	let div2;
	let div0;
	let t1;
	let div1;
	let t2;
	let t3;
	let t4;
	let t5;
	let button;
	let current;
	let mounted;
	let dispose;

	shader = new Shader({
			props: {
				controlsArray: /*controlsArrays*/ ctx[0][/*shaderName*/ ctx[1]],
				shader: /*shaderFrag*/ ctx[2][/*shaderName*/ ctx[1]]
			},
			$$inline: true
		});

	let each_value = /*controlsArrays*/ ctx[0][/*shaderName*/ ctx[1]];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	const block = {
		c: function create() {
			create_component(shader.$$.fragment);
			t0 = space();
			div3 = element("div");
			div2 = element("div");
			div0 = element("div");
			t1 = space();
			div1 = element("div");
			t2 = text(/*shaderName*/ ctx[1]);
			t3 = text(" by Pre-logo");
			t4 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t5 = space();
			button = element("button");
			button.textContent = "Download image";
			attr_dev(div0, "class", "logo svelte-lg7b2e");
			add_location(div0, file, 137, 2, 4046);
			attr_dev(div1, "class", "title svelte-lg7b2e");
			add_location(div1, file, 138, 2, 4073);
			attr_dev(div2, "class", "header svelte-lg7b2e");
			add_location(div2, file, 136, 1, 4023);
			attr_dev(button, "class", "svelte-lg7b2e");
			add_location(button, file, 145, 1, 4271);
			attr_dev(div3, "class", "control-panel svelte-lg7b2e");
			add_location(div3, file, 135, 0, 3994);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			mount_component(shader, target, anchor);
			insert_dev(target, t0, anchor);
			insert_dev(target, div3, anchor);
			append_dev(div3, div2);
			append_dev(div2, div0);
			append_dev(div2, t1);
			append_dev(div2, div1);
			append_dev(div1, t2);
			append_dev(div1, t3);
			append_dev(div3, t4);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div3, null);
			}

			append_dev(div3, t5);
			append_dev(div3, button);
			current = true;

			if (!mounted) {
				dispose = listen_dev(button, "click", /*saveImage*/ ctx[3], false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			const shader_changes = {};
			if (dirty & /*controlsArrays, shaderName*/ 3) shader_changes.controlsArray = /*controlsArrays*/ ctx[0][/*shaderName*/ ctx[1]];
			if (dirty & /*shaderFrag, shaderName*/ 6) shader_changes.shader = /*shaderFrag*/ ctx[2][/*shaderName*/ ctx[1]];
			shader.$set(shader_changes);
			if (!current || dirty & /*shaderName*/ 2) set_data_dev(t2, /*shaderName*/ ctx[1]);

			if (dirty & /*controlsArrays, shaderName*/ 3) {
				each_value = /*controlsArrays*/ ctx[0][/*shaderName*/ ctx[1]];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div3, t5);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(shader.$$.fragment, local);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o: function outro(local) {
			transition_out(shader.$$.fragment, local);
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(shader, detaching);
			if (detaching) detach_dev(t0);
			if (detaching) detach_dev(div3);
			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots("App", slots, []);
	let controlsArrays = {};

	controlsArrays["Open eye"] = [
		{
			name: "Lightness threshold",
			id: "threshold",
			min: -1,
			value: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "Color shift",
			id: "colorShift_",
			value: 0.02,
			max: 0.1,
			step: 0.01,
			min: 0
		},
		{
			name: "Spot seed",
			id: "spotSeed",
			value: 0,
			max: 1,
			step: 0.01,
			min: 0
		},
		{
			name: "Spot radius",
			id: "spotRadius",
			value: 0.5,
			max: 1,
			step: 0.01,
			min: 0
		},
		{
			name: "Spot details",
			id: "spotDetails",
			value: 0.5,
			max: 1,
			step: 0.01,
			min: 0
		},
		{
			name: "Spot amplitude",
			id: "spotAmplitude",
			value: 0.5,
			max: 1,
			step: 0.01,
			min: 0
		},
		{
			name: "Blur",
			id: "blur",
			value: 0.1,
			max: 1,
			step: 0.01,
			min: 0
		},
		{
			name: "Time",
			id: "time_",
			value: 0,
			max: 500,
			step: 0.1,
			step: 0.01,
			min: 0
		}
	];

	controlsArrays["Bubble"] = [
		{
			name: "time_",
			id: "time_",
			value: 0.1,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "distance",
			id: "distance",
			max: 50,
			value: 2.1,
			min: 0,
			step: 0.01
		},
		{
			name: "refK",
			id: "refK",
			max: 10,
			value: 0.8,
			min: 0,
			step: 0.01
		},
		{
			name: "bgK",
			id: "bgK",
			max: 10,
			value: 2,
			min: 0,
			step: 0.01
		},
		{
			name: "edgeK",
			id: "edgeK",
			max: 10,
			value: 2.1,
			min: 0,
			step: 0.01
		},
		{
			name: "rainbowK",
			id: "rainbowK",
			max: 10,
			value: 0.5,
			min: 0,
			step: 0.01
		},
		{
			name: "noiseAmp",
			id: "noiseAmp",
			value: 1.1,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "noiseFreq",
			id: "noiseFreq",
			max: 10,
			value: 1.2,
			min: 0,
			step: 0.01
		},
		{
			name: "mousex",
			id: "mousex",
			max: 1,
			min: 0,
			value: 0,
			step: 0.01
		}
	];

	controlsArrays["Yomqo"] = [
		{
			name: "time_",
			id: "time_",
			value: 0.1,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "stripeWidth",
			id: "stripeWidth",
			value: 0.05,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "stripeNoise_",
			id: "stripeNoise_",
			value: 1,
			min: 0,
			max: 1,
			step: 1
		},
		{
			name: "stripeOpacity",
			id: "stripeOpacity",
			value: 0.2,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "noiseAmp",
			id: "noiseAmp",
			value: 0.02,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "noiseFreq",
			id: "noiseFreq",
			value: 0.2,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "bulbColorR",
			id: "bulbColorR",
			value: 1,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "bulbColorG",
			id: "bulbColorG",
			value: 0.97,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "bulbColorB",
			id: "bulbColorB",
			value: 0.93,
			min: 0,
			max: 1,
			step: 0.01
		},
		{
			name: "mousex",
			id: "mousex",
			max: 1,
			min: 0,
			value: 0,
			step: 0.01
		}
	];

	controlsArrays["test"] = [
		{
			name: "val",
			id: "val",
			min: 0,
			value: 0,
			max: 1,
			step: 0.01
		}
	];

	let shaderName = "Open eye";

	if (window.location.hash) {
		shaderName = window.location.hash.slice(1); //.toLowerCase()
	}

	let shaderFrag = {};
	shaderFrag["Yomqo"] = yomqo;
	shaderFrag["Bubble"] = bubble;
	shaderFrag["Open eye"] = openeye;
	shaderFrag["test"] = test;

	let saveImage = () => {
		let canvas = document.querySelector("#canvas-main");
		var link = document.createElement("a");
		link.download = "image.png";
		link.href = canvas.toDataURL("image/png");
		link.click();
	};

	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== "$$") console.warn(`<App> was created with unknown prop '${key}'`);
	});

	function control_value_binding(value, c) {
		c.value = value;
		$$invalidate(0, controlsArrays);
	}

	$$self.$capture_state = () => ({
		Control,
		Shader,
		controlsArrays,
		shaderName,
		shaderFrag,
		yomqo,
		bubble,
		openeye,
		test,
		saveImage
	});

	$$self.$inject_state = $$props => {
		if ("controlsArrays" in $$props) $$invalidate(0, controlsArrays = $$props.controlsArrays);
		if ("shaderName" in $$props) $$invalidate(1, shaderName = $$props.shaderName);
		if ("shaderFrag" in $$props) $$invalidate(2, shaderFrag = $$props.shaderFrag);
		if ("saveImage" in $$props) $$invalidate(3, saveImage = $$props.saveImage);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [controlsArrays, shaderName, shaderFrag, saveImage, control_value_binding];
}

class App extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "App",
			options,
			id: create_fragment.name
		});
	}
}

export default App;